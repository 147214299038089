import { createRootRoute, Outlet } from "@tanstack/react-router";
import React, { Suspense } from "react";
import Header from "@/components/Header";

const TanStackRouterDevtools =
  import.meta.env.MODE === "production"
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        }))
      );

export const Route = createRootRoute({
  component: () => (
    <>
      <Header />
      <article className="mx-10 my-10 sm:mt-16 sm:ml-[25%]">
        <Outlet />
      </article>
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  ),
});
