// import { Link } from "@tanstack/react-router";
import "./Header.css";
import SpongeBob from "@/assets/sponge.webp";

export default function Header() {
  return (
    <>
      <header className="header flex flex-col sm:w-[20%] sm:fixed items-center justify-center my-10">
        <section className="text-gray-800 flex flex-col items-center space-y-3">
          <a
            href="/"
            className=" w-32"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <img
              className="w-full h-full object-contain rounded-full"
              loading="lazy"
              src={SpongeBob}
              alt="Anthony Malkoun's Github Avatar"
            />
          </a>
          <div className="text-center sm:max-w-44 space-y-1">
            <h1 className="uppercase font-bold text-black">Anthony Malkoun</h1>
            <h2 className="text-sm">AI n' stuff.</h2>
          </div>
          <div className="flex space-x-2">
            <a
              className="text-gray-800 hover:text-purple-600 transition-all duration-300"
              href="https://github.com/anthony2261"
              title="My GitHub"
              aria-label="My GitHub"
            >
              <svg
                viewBox="0 0 512 512"
                height="25"
                width="25"
                aria-hidden="true"
                focusable="false"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                className="inline-block align-middle"
              >
                <path d="M256 32C132.3 32 32 134.9 32 261.7c0 101.5 64.2 187.5 153.2 217.9a17.56 17.56 0 003.8.4c8.3 0 11.5-6.1 11.5-11.4 0-5.5-.2-19.9-.3-39.1a102.4 102.4 0 01-22.6 2.7c-43.1 0-52.9-33.5-52.9-33.5-10.2-26.5-24.9-33.6-24.9-33.6-19.5-13.7-.1-14.1 1.4-14.1h.1c22.5 2 34.3 23.8 34.3 23.8 11.2 19.6 26.2 25.1 39.6 25.1a63 63 0 0025.6-6c2-14.8 7.8-24.9 14.2-30.7-49.7-5.8-102-25.5-102-113.5 0-25.1 8.7-45.6 23-61.6-2.3-5.8-10-29.2 2.2-60.8a18.64 18.64 0 015-.5c8.1 0 26.4 3.1 56.6 24.1a208.21 208.21 0 01112.2 0c30.2-21 48.5-24.1 56.6-24.1a18.64 18.64 0 015 .5c12.2 31.6 4.5 55 2.2 60.8 14.3 16.1 23 36.6 23 61.6 0 88.2-52.4 107.6-102.3 113.3 8 7.1 15.2 21.1 15.2 42.5 0 30.7-.3 55.5-.3 63 0 5.4 3.1 11.5 11.4 11.5a19.35 19.35 0 004-.4C415.9 449.2 480 363.1 480 261.7 480 134.9 379.7 32 256 32z"></path>
              </svg>
            </a>
            <a
              className="text-gray-800 hover:text-purple-600 transition-all duration-300"
              href="mailto:antounmalkoun@gmail.com"
              title="Say hello"
            >
              <svg
                viewBox="0 0 512 512"
                height="25"
                width="25"
                aria-hidden="true"
                focusable="false"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                className="inline-block align-middle"
              >
                <path d="M476.59 227.05l-.16-.07L49.35 49.84A23.56 23.56 0 0027.14 52 24.65 24.65 0 0016 72.59v113.29a24 24 0 0019.52 23.57l232.93 43.07a4 4 0 010 7.86L35.53 303.45A24 24 0 0016 327v113.31A23.57 23.57 0 0026.59 460a23.94 23.94 0 0013.22 4 24.55 24.55 0 009.52-1.93L476.4 285.94l.19-.09a32 32 0 000-58.8z"></path>
              </svg>
            </a>
          </div>
        </section>
      </header>
      {/* <div className="p-2 flex gap-2">
        <Link to="/" className="[&.active]:font-bold">
          Home
        </Link>{" "}
        <Link to="/about" className="[&.active]:font-bold">
          About
        </Link>
      </div>
      <hr /> */}
    </>
  );
}
